<template>
    <v-container>
        <template>
            <v-card>
                <v-tabs
                    v-model="tabNotification"
                    background-color="#06466E"
                    centered
                    dark
                    icons-and-text
                    show-arrows="mobile"
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-1">
                    List
                    <v-icon>mdi-table</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2">
                    Add Public Holiday
                    <v-icon>mdi-calendar</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabNotification">
                    <v-tab-item
                    :value="'tab-1'"
                    >
                    <v-card flat>
                        <template>
                            <v-container>
                                <v-card>
                                    <v-card-text>
                                            <ul class="bullet-icon">

                                                <li>
                                                <v-icon class="mr-2" small>mdi-trash-can-outline</v-icon>
                                                    Click Remove button at List to delete Public Holiday.
                                                </li>

                                                <li>
                                                <v-icon class="mr-2" small>mdi-calendar</v-icon>
                                                    To create new Public Holiday on working holidays, click at any date at Calendar tab.
                                                </li>

                                            </ul>
                                        </v-card-text>
                                </v-card>
                            </v-container>
                        </template>
                        <template>
                            <v-container>
                                <v-card>
                                    <v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            outlined
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-data-table
                                            :headers="headers"
                                            :items="publicHoliday"
                                            :search="search"
                                            class="elevation-1"
                                            :sort-by="['start']"
                                            :sort-desc="[true]"
                                            :custom-sort="customSort"
                                        >
                                            <!-- <template v-slot:[`item.start`]="{ item }">
                                                <span>{{ new Date(item.start).toLocaleDateString('en-GB') }}</span>
                                            </template>
                                            <template v-slot:[`item.end`]="{ item }">
                                                <span>{{ new Date(item.end).toLocaleDateString('en-GB') }}</span>
                                            </template> -->
                                            <template v-slot:[`item.remove`]="props">
                                                <template>
                                                    <v-icon
                                                    dense
                                                    class="mr-2 red--text" @click="deletePublicHolidayData = props.item; deletePublicHolidayDialog = true"
                                                    >
                                                        mdi-trash-can-outline
                                                    </v-icon>
                                                </template>
                                            </template>
                                            
                                        </v-data-table>

                                    </v-card-text>
                                </v-card>
                            </v-container>
                        </template>
                    </v-card>
                    </v-tab-item>
                    <v-tab-item
                    :value="'tab-2'"
                    >
                    <v-card flat>
                        <v-container>
                            <template>
                                <v-row class="fill-height">
                                    <v-col>
                                    <v-sheet class="calendarSizing" height="65">
                                        <v-toolbar
                                        flat
                                        >
                                        <v-btn
                                            outlined
                                            class="mr-4"
                                            color="grey darken-2"
                                            @click="setToday"
                                        >
                                            Today
                                        </v-btn>
                                        <v-btn
                                            fab
                                            text
                                            small
                                            color="grey darken-2"
                                            @click="prev"
                                        >
                                            <v-icon small>
                                            mdi-chevron-left
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            text
                                            small
                                            color="grey darken-2"
                                            @click="next"
                                        >
                                            <v-icon small>
                                            mdi-chevron-right
                                            </v-icon>
                                        </v-btn>
                                        <v-toolbar-title v-if="$refs.calendar">
                                            {{ $refs.calendar.title }}
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-menu
                                            bottom
                                            right
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                outlined
                                                color="grey darken-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <span>{{ typeToLabel[type] }}</span>
                                                <v-icon right>
                                                mdi-menu-down
                                                </v-icon>
                                            </v-btn>
                                            </template>
                                            <v-list>
                                            <v-list-item @click="type = 'day'">
                                                <v-list-item-title>Day</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="type = 'week'">
                                                <v-list-item-title>Week</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="type = 'month'">
                                                <v-list-item-title>Month</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="type = '4day'">
                                                <v-list-item-title>4 days</v-list-item-title>
                                            </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        </v-toolbar>
                                    </v-sheet>
                                    <v-sheet height="600">
                                        <v-calendar
                                        ref="calendar"
                                        v-model="focus"
                                        color="primary"
                                        :events="events"
                                        :event-color="getEventColor"
                                        :type="type"
                                        @click:event="showEvent"
                                        @click:more="viewDay"
                                        @click:date="viewDay"
                                        @change="updateEvent"
                                        ></v-calendar>
                                        <v-menu
                                        v-model="selectedOpen"
                                        :close-on-content-click="false"
                                        :activator="selectedElement"
                                        offset-x
                                        max-width="500px"
                                        >
                                        <v-card
                                            color="grey lighten-4"
                                            min-width="300px"
                                            flat
                                        >
                                            <v-toolbar
                                            :color="selectedEvent.color"
                                            dark
                                            dense
                                            >
                                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn 
                                                icon 
                                                @click="selectedOpen = false"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            </v-toolbar>
                                            <v-card-text>
                                                <v-simple-table dense>
                                                    <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <v-icon class="mr-2" small>mdi-calendar-today</v-icon>
                                                                <span>Start Date</span>
                                                            </td>
                                                            <td>
                                                                <span>{{ new Date(selectedEvent.start).toLocaleDateString('en-GB') }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <v-icon class="mr-2" small>mdi-calendar</v-icon>
                                                                <span>End Date</span>
                                                            </td>
                                                            <td>
                                                                <span>{{ new Date(selectedEvent.end).toLocaleDateString('en-GB') }}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card-text>
                                            <!-- <v-card-actions>
                                            <v-btn
                                                text
                                                color="secondary"
                                                @click="selectedOpen = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            </v-card-actions> -->
                                        </v-card>
                                        </v-menu>
                                    </v-sheet>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-container>
                    </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </template>

        <!-- Add new public holiday -->
        <template>
            <v-dialog
            v-model="addPublicHolidayDialog"
            width="600"
            scrollable
            >
            <v-form
                ref="publicHolidayForm"
                v-model="valid"
                lazy-validation
            >
                <v-card>
                    <v-card-title class="justify-center title-bar-modul">
                        <h4>Add Public Holiday</h4>
                    </v-card-title>

                    <v-card-text>
                        <v-row>

                            <v-col
                                class="d-flex mt-2"
                                cols="12"
                                sm="12"
                            >
                                <v-text-field
                                    v-model="publicHolidayData.name"
                                    outlined
                                    label="Description"
                                    dense
                                    hide-details="auto"
                                    :rules="[v => !!v || 'Please enter your description']"
                                ></v-text-field>
                            </v-col>

                            <template>
                                <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="6"
                                >
                                    <v-menu
                                    v-model="modalCalendarFromPublicHoliday"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    hide-details="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="valueFromDate"
                                            label="Start Date"
                                            prepend-icon="mdi-calendar"
                                            hide-details=""

                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="valueFromDate"
                                            v-on:change="valueToDate = valueFromDate;"
                                            @input="modalCalendarFromPublicHoliday = false"
                                        >
                                            <v-spacer></v-spacer>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="6"
                                >
                                    <v-menu
                                    v-model="modalCalendarToPublicHoliday"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    hide-details="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="valueToDate"
                                            label="End Date"
                                            prepend-icon="mdi-calendar"
                                            hide-details=""

                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="valueToDate"
                                            @input="modalCalendarToPublicHoliday = false"
                                            :min="valueFromDate"
                                        >
                                            <v-spacer></v-spacer>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>
                        
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        outlined
                        @click="addPublicHolidayDialog = false; $refs.publicHolidayForm.reset()"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        outlined
                        @click="submitPublicHolidayForm()"
                    >
                        Add
                    </v-btn>
                    </v-card-actions>
                </v-card>
                
            </v-form>
            </v-dialog>
        </template>

        <template>
            <v-dialog
            v-model="deletePublicHolidayDialog"
            width="600"
            scrollable
            >
            <v-card>
                <v-card-title class="justify-center title-bar-modul">
                    <h4>Delete Public Holiday</h4>
                </v-card-title>

                <v-card-text>
                    <v-row>

                        <v-col
                            class="d-flex mt-2"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                v-model="deletePublicHolidayData.name"
                                outlined
                                label="Description"
                                dense
                                hide-details="auto"
                                readonly
                            ></v-text-field>
                        </v-col>

                        <template>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="6"
                            >
                            <v-text-field
                                v-model="formattedStart"
                                label="Start Date"
                                prepend-icon="mdi-calendar"
                                dense
                                hide-details="auto"
                                readonly
                            ></v-text-field>
                            
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="6"
                            >
                            <v-text-field
                                v-model="formattedEnd"
                                label="End Date"
                                prepend-icon="mdi-calendar"
                                dense
                                hide-details="auto"
                                readonly
                            ></v-text-field>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>
                    
                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    outlined
                    @click="deletePublicHolidayDialog = false; deletePublicHolidayData = ''"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="red"
                    outlined
                    @click="deletePublicHoliday();"
                >
                    Delete
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>

        <template>
            <div class="text-center">
                <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                color="success"
                >

                {{ snackbarMsg }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
                </v-snackbar>
            </div>
        </template>
    </v-container>
</template>

<script>
import axios from 'axios';
export default {
    data: () => ({
        valid: true,
        search: '',
        publicHoliday: [],
        headers: [
            { text: 'Start Date', value: 'start' },
            { text: 'End Date', value: 'end' },
            { text: 'Description', value: 'name' },
            { text: 'Remove', value: 'remove' },
        ],
        tabNotification: null,

        // Calendar
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: [
            {
                leave_type: 'ANNUAL LEAVE',
                color: 'indigo'
            },
            {
                leave_type: 'MEDICAL LEAVE',
                color: 'cyan'
            }
        ],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],

        // dialog
        addPublicHolidayDialog: false,
        deletePublicHolidayDialog: false,
        valueFromDate: '',
        valueToDate: '',
        modalCalendarToPublicHoliday: false,
        modalCalendarFromPublicHoliday: false,

        publicHolidayData: {
            name: '',
            startdate: '',
            enddate: ''
        },

        deletePublicHolidayData: '',
        snackbar: false,
        timeout: 3000,
        snackbarMsg: '',
        

    }),
    computed: {
        formattedStart() {
            return new Date(this.deletePublicHolidayData.start).toLocaleDateString()
        },
        formattedEnd() {
            return new Date(this.deletePublicHolidayData.end).toLocaleDateString()
        }
    },
    methods: {

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);
            
            items.sort((a, b) => {
                if (index[0]=='start' || index[0]=='end') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                    }
                }
            });
            return items;
        },

        getAllPublicHoliday(){
            axios.get(process.env.VUE_APP_API_URL + 'cuti/publicholiday', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {//eslint-disable-line

                for(let i = 0; i < response.data.length; i++){
                    response.data[i].start = this.convertDateString(response.data[i].start) 
                    response.data[i].end = this.convertDateString(response.data[i].end) 
                }

                // console.log(response.data);

                this.publicHoliday = response.data;

            })
            .catch(error => {
                console.log(error);
            })
        },
        viewDay ({ date }) {
            this.focus = date;
            this.valueFromDate = date;
            this.valueToDate = date;
            this.addPublicHolidayDialog = true;
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
            open()
            }

            nativeEvent.stopPropagation()
        },
        changeColor(leave_type){
            let colors = this.colors;
            for (let i = 0; i < colors.length; i++){
                if(colors[i].leave_type == leave_type){
                    return colors[i].color;
                }
            }
        },
        updateEvent () {

            // let events = [];
            axios.get(process.env.VUE_APP_API_URL + 'cuti/publicholiday', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.events = response.data;
            })
            .catch(error => {
                console.log(error);
            })

        },
        submitPublicHolidayForm(){
            if(this.$refs.publicHolidayForm.validate() == true){
                
                const payload = {
                    name: this.publicHolidayData.name,
                    startdate: this.valueFromDate,
                    enddate: this.valueToDate
                };

                // console.log(payload);

                axios.post(process.env.VUE_APP_API_URL + 'cuti/publicholidayadd', payload, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token,
                    }
                })
                .then((response) => {//eslint-disable-line
                    this.addPublicHolidayDialog = false;
                    this.snackbarMsg = 'Public Holiday successfully added!';
                    this.snackbar = true;
                    this.getAllPublicHoliday();
                    this.updateEvent();
                    this.$refs.publicHolidayForm.reset();
                    // console.log(response.data);
                    
                })
                .catch(error => {
                    console.log(error);
                });

            }
        },
        deletePublicHoliday(){

            let item = this.deletePublicHolidayData;

            axios.get(process.env.VUE_APP_API_URL + 'cuti/publicholidaydel?name=' + item.name + '&startdt=' + item.start, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {//eslint-disable-line
                // console.log(response.data);
                this.deletePublicHolidayDialog = false;
                this.deletePublicHolidayData = '';
                this.snackbarMsg = 'Public Holiday successfully deleted!';
                this.snackbar = true;
                this.getAllPublicHoliday();
            })
            .catch(error => {
                console.log(error);
            })
        },
    },
    mounted (){
        this.getAllPublicHoliday();
    }
}

</script>

<style lang="scss">
@import '~scss/main';



</style>
