<template>
    <v-container>
        <v-card class="mb-5">
            <v-card-text>
                <ul class="bullet-icon">
                    <li>
                        <v-icon class="mr-2" small>mdi-calendar-alert</v-icon>
                        Click at button below to create/post new announcement. Click Detail button for more info or Remove button to delete announcement.
                    </li>
                    <li>
                        <v-icon class="mr-2" small>mdi-bullhorn-outline</v-icon>
                        This icon refers to the post type of Main Announcement (slider post).
                    </li>
                    <li>
                        <v-icon class="mr-2" small>mdi-newspaper-variant-multiple-outline</v-icon>
                        This icon refers to the post type of News.
                    </li>
                </ul>
            </v-card-text>
        </v-card>
        
        <v-card>
            <template>

            <v-card class="my-5 darkTable">
                <v-card-title class="dtcard-on-card">
                    <v-btn
                    color="primary"
                    dark
                    @click="dialogFormPost = true"
                    >
                    <v-icon
                    dark
                    left
                    >
                    mdi-plus
                    </v-icon>
                        Announcement
                    </v-btn>
                    <v-tooltip max-width="450" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            class="ml-4"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            >
                            mdi-information
                            </v-icon>
                        </template>
                        <span>For information, only 5 of latest post will be display in main page of announcement view.</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchDtAnnouncement"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                        dense
                        class="shrink mt-2"
                    ></v-text-field>
                </v-card-title>
            
                <v-data-table
                    :headers="headers"
                    :items="tbodyAnnouncements"
                    :items-per-page="5"
                    class="elevation-1"
                    :search="searchDtAnnouncement"
                    :loading="loadingDt"
                    :custom-sort="customSort"
                >
                    <template v-slot:[`item.picture`]="{ item }">
                        <v-card
                        class="ma-4 text-left"
                        height="auto"
                        elevation="0"
                        width="150"
                        >
                            <v-img tile height="80" class="mt-2" :src="item.picture"></v-img>
                        </v-card>
                    </template>

                    <template v-slot:[`item.type`]="{ item }">
                        <v-icon dense v-if="item.type == 'Announcement'" class="mr-22 blue--text">mdi-bullhorn-outline</v-icon>
                        <v-icon dense v-if="item.type == 'News'" class="mr-2 success--text">mdi-newspaper-variant-multiple-outline</v-icon>
                        <!-- {{item.type}} -->
                    </template>

                    <template v-slot:[`item.dateApplied`]="{ item }">
                        <span v-html="convertDateTime(item.dateApplied)"></span>
                    </template>

                    <template v-slot:[`item.detail`]="{ item }">
                        <v-icon
                        color="primary"
                        dense
                        v-html="item.detail"
                        @click="openFileDoc(item)"
                        >
                        </v-icon>
                    </template>

                    <template v-slot:[`item.delete`]="{ item }">
                        <v-icon
                        color="error"
                        dense
                        v-html="item.delete"
                        @click="confirmationDelete(item)"
                        >
                        </v-icon>
                    </template>


                </v-data-table>
            </v-card>
            </template>
        </v-card>


        <!-- Dialog Form Announcement -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogFormPost"
                scrollable
                persistent
                max-width="700"
                >
                    <v-card>
                        <v-card-title class="justify-center title-bar-modul">New Announcement</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="overflow-x: hidden;">
                        <v-form ref="form">
                            <v-container>
                                <v-row class="mt-1">

                                    <!-- Option Post Type -->
                                    <v-col cols="12" sm="6">
                                        
                                        <div class="subtitle-2">
                                            <span class="text-bold red--text"> *</span>
                                            Post Type:
                                        </div> 
                                        <v-radio-group
                                        v-model="postOptional"
                                        @change="seeExample(postOptional)"
                                        row
                                        >
                                        <v-radio
                                            label="Announcement"
                                            value="A"
                                        ></v-radio>
                                        <v-radio
                                            label="News"
                                            value="N"
                                        ></v-radio>
                                        </v-radio-group>

                                        <small>**Post as "Announcement" will be post to the main slide view & list of latest news. If "News" is selected, it will be post only in list of latest news</small>
                                    </v-col>

                                    <v-divider
                                    v-if="newsIsSelected"
                                    vertical
                                    aria-orientation="horizontal"
                                    ></v-divider>

                                    <v-col cols="12" sm="6" v-if="newsIsSelected">
                                        
                                        <div class="subtitle-2 primary--text">The News View (Sample):</div>

                                        <v-card
                                        class="mt-4 pa-2 text-left"
                                        height="auto"
                                        width="180"
                                        :loading="loadingImageUpload"
                                        tile
                                        >   
                                            
                                            <v-icon dense class="darkk-text mr-2">mdi-calendar</v-icon>
                                            <small class="pa-0 text-bold caption" v-text="convertDateString(dateToday)"></small>

                                            <v-img tile width="auto" height="80" class="mt-2" :src="dataPost.picture"></v-img>

                                            <small class="pa-0 caption font-weight-regular" v-text="dataPost.title"></small><br>
                                            
                                            
                                        </v-card>
                                    </v-col>

                                    <!-- Title Announcement/News -->
                                    <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="12"
                                    >
                                        <v-text-field
                                        outlined
                                        label="Title"
                                        dense
                                        v-model="dataPost.title"
                                        hide-details="auto"
                                        :rules="rules.eventTitle"
                                        @input="typingTitle()"
                                        ></v-text-field>
                                    </v-col>

                                    <!-- Description -->
                                    <div class="subtitle-2 ml-4 mt-5">
                                        <v-icon dense>mdi-pencil</v-icon>
                                        Description
                                    </div>
                                    <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="12"
                                    >
                                        <wysiwyg v-model="dataPost.details" />
                                    </v-col>

                                    <!-- Upload Main Image -->
                                    <small class=" ml-4 mt-5">
                                        <span class="red--text">*</span>
                                        For the best view, we recommend to use 1570 x 720 of image pixel.
                                    </small>
                                    <v-col
                                    class="d-flex py-0"
                                    cols="12"
                                    sm="6"
                                    >
                                        <v-file-input
                                        label="Main Image"
                                        accept="image/png, image/jpeg, image/bmp"
                                        prepend-icon="mdi-camera"
                                        outlined
                                        dense
                                        :loading="loadingImageUpload"
                                        v-model="mainImage"
                                        v-on:change="handleFileUpload( $event )"
                                        ></v-file-input>
                                    </v-col>
                            

                                    <!-- Choose Main Image -->
                                    <v-col
                                    class="d-flex pt-0"
                                    cols="12"
                                    sm="12"
                                    >
                                        <v-card class="pt-2">
                                            <template>
                                            <small class="ml-4 caption"><span class="text-bold">**</span> OR choose provided image for Main Image:</small>
                                            <v-sheet
                                            class="mx-auto"
                                            elevation="0"
                                            >
                                                <v-slide-group
                                                class="pa-0"
                                                active-class="success"
                                                show-arrows
                                                >
                                                <v-slide-item
                                                    v-for="(item, i) in provideImage"
                                                    :key="i"
                                                >
                                                    <v-card
                                                    class="ma-4 text-left"
                                                    height="auto"
                                                    elevation="5"
                                                    width="120"
                                                    @click="selectBgImage(item.url)"
                                                    >
                                                        <v-img :src="item.url"></v-img>
                                                    </v-card>
                                                </v-slide-item>
                                                </v-slide-group>
                                            </v-sheet>
                                            </template>
                                        </v-card>
                                    </v-col>
                                    


                                </v-row>
                            </v-container>
                        </v-form>
                        <small class="text-bold"><span class="red--text text-bold">*</span> indicates required field</small>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="primary--text text-bold"
                            text
                            @click="cancelForm"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            class="primary--text text-bold"
                            text
                            :disabled="disableBtnPost"
                            @click="postForm"
                        >
                            Post
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>


        <!-- Dialog View Details Announcement -->
        <template>
            <v-row justify="center">
                <v-dialog
                persistent
                v-model="dialogDetailsAnnounce"
                scrollable
                max-width="650px"
                >
                    <v-card>
                        <v-card-title class="justify-center title-bar-modul">Details Announcement</v-card-title>
                        <v-card-text>
                            <v-row>
                                <!-- <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="12"
                                >
                                    <v-img
                                        :src="announcement[0].source"
                                        aspect-ratio="1"
                                        class="grey lighten-2"
                                    >
                                    </v-img>
                                </v-col> -->
                                <v-col
                                    class="d-flex"
                                    cols="12"
                                    sm="12"
                                >
                                    <h3>
                                        {{ dataEdit.title }}
                                    </h3>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                >
                                    <span v-html="dataEdit.details">

                                    </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="primary--text text-bold"
                                text
                                @click="dialogDetailsAnnounce = false"
                            >
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Success Post Announcement -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccessPostAnnc"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 success--text">
                        <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                        Success!
                    </v-card-title>
                    <v-card-text class="blackk--text">New {{newWhat}} has been successfully posted.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogSuccessPostAnnc = false; dialogFormPost = false; getAllData();"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Delete Post Annnouncement -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogConfirmDel"
                persistent
                max-width="350"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-delete-empty</v-icon>
                        Delete Announcement?
                    </v-card-title>
                    <v-card-text class="blackk--text">Are you sure want to delete this post?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogConfirmDel = false;"
                    >
                        NO
                    </v-btn>
                    <v-btn
                        class="text-bold red--text"
                        text
                        @click="dialogConfirmDel = false; deleteThisPost();"
                    >
                        YES
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Error -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogError"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Failed!
                    </v-card-title>
                    <v-card-text class="blackk--text">Something went wrong. Please try again.</v-card-text>
                    <br>
                    <v-card-text>
                        <small class="primary--text">
                            <span class="red--text text-bold">**</span> If the error still occurs, please contact HR.
                        </small> 
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogError = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Warning Blank Field Form -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="errorBlankFill"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Warning!
                    </v-card-title>
                    <v-card-text class="blackk--text">Please fill out all of required field.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="errorBlankFill = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>
        
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
     data: () => ({

        newWhat: '',
        dialogError: false,

        //  Datatable
        searchDtAnnouncement: '',
        loadingDt: false,
         headers: [
          { text: '', value: 'picture', sortable: false },
          { text: 'Title', value: 'title' },
          { text: 'Post Type', value: 'type' },
          { text: 'Date Posted', value: 'dateApplied'},
          { text: 'Details', value: 'detail', sortable: false },
          { text: 'Remove', value: 'delete', sortable: false }
        ],
        tbodyAnnouncements: [],
        dataEdit: {
            title: '',
            details: '',
            picture: '',
            dateApplied: ''
        },
        deletePost: {
            iduser: '',
            title: '',
            details: '',
            picture: '',
            dateApplied: '',
            type: ''
        },
        dialogDetailsAnnounce: false,
        dialogConfirmDel: false,


        // Form
        dialogFormPost: false,
        postOptional: null,
        // myHTML: "",
        mainImage: null,
        loadingImageUpload: false,
        provideImage: [
            {url: require("@/assets/img/memo/memo0.jpg")},
            {url: require("@/assets/img/memo/memo8.jpg")},
            {url: require("@/assets/img/memo/memo7.jpg")},
            {url: require("@/assets/img/memo/memo1.jpg")},
            {url: require("@/assets/img/memo/memo3.jpg")},
        ],
        dataPost: {
            iduser: '',
            title: '',
            type: '',
            details: '',
            picture: '',
        },
        newsIsSelected: true,
        dateToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dialogSuccessPostAnnc: false,
        errorBlankFill: false,
        rules: {
          eventTitle: [val => (val || '').length > 0 || 'This title is required'],
        },
        disableBtnPost: true,

    }),
    methods: {

        load(){
            this.dataPost.iduser = this.$store.getters.user.idEmp;
        },

        // Datatable
        getAllData(){

            this.loadingDt = true;

            let dtToday = new Date(Date.now());
            let thisYear = dtToday.getFullYear()

            axios.get(process.env.VUE_APP_API_URL + 'announce/all?startdt='+thisYear+'-01-01&enddt='+thisYear+'-12-31', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                response.data.forEach(function(res){
                    res['detail'] = 'mdi-file-document'
                    res['delete'] = 'mdi-trash-can-outline'
                    if (res['type'] == 'A') {
                        res['type'] = "Announcement";
                    }
                    else if (res['type'] == 'N') {
                        res['type'] = "News";
                    }
                })
                this.tbodyAnnouncements = response.data;
                this.loadingDt = false;
                // console.log(this.tbodyAnnouncements);
            })
            .catch(error => {
                console.log(error.response);
                this.loadingDt = false;
            })

        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            items.sort((a, b) => {
                if (index[0]=='dateApplied') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        openFileDoc(item){
            this.dataEdit.title = item.title;
            this.dataEdit.details = item.details;
            this.dataEdit.picture = item.picture;
            this.dataEdit.dateApplied = item.dateApplied;
            this.dialogDetailsAnnounce = true;
        },

        confirmationDelete(item){

            // console.log(item);

            this.dialogConfirmDel = true;

            this.deletePost.title = item.title;
            this.deletePost.details = item.details;
            this.deletePost.picture = item.picture;
            this.deletePost.dateApplied = item.dateApplied;
            this.deletePost.iduser = item.iduser;
            this.deletePost.type = item.type.charAt(0);


        },

        deleteThisPost(){

            axios.get(process.env.VUE_APP_API_URL + 'announce/del?idemp='+this.deletePost.iduser+'&dateapplied='+this.deletePost.dateApplied+'&title='+this.deletePost.title+'&type='+this.deletePost.type, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => { //eslint-disable-line
                // console.log(response);
                this.getAllData();
            })
            .catch(error => {
                console.log(error.response);
                this.dialogError = true;
            })

            // console.log(process.env.VUE_APP_API_URL + 'announce/del?idemp='+this.deletePost.iduser+'&dateapplied='+this.deletePost.dateApplied+'&title='+this.deletePost.title+'&type='+this.deletePost.type);

        },


        // Form Input New Announcement
        typingTitle(){

            if (this.dataPost.title != "" && this.dataPost.type != "" && this.dataPost.picture != "") {
                this.disableBtnPost = false;
            }
            else{
                this.disableBtnPost = true;
            }

        },

        handleFileUpload(data){

            this.loadingImageUpload = true;

            let formData = new FormData();

            formData.append("files", data);

            axios.post(process.env.VUE_APP_API_URL + `employees/upload`, formData, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                },
            })
            .then((response) => {
                // console.log(response);
                this.dataPost.picture = response.data.filepath;
                // console.log(this.jsonEditBookCar.mileagecar);
                this.loadingImageUpload = false;

                if (this.dataPost.title != "" && this.dataPost.type != "" && this.dataPost.picture != "") {
                    this.disableBtnPost = false;
                }
                else{
                    this.disableBtnPost = true;
                }
            })
            .catch((error) => {//eslint-disable-line
                
                // console.log("nullImage");
                this.loadingImageUpload = false;
            });
        },

        seeExample(radio){

            this.dataPost.type = radio;

            if (this.dataPost.title != "" && this.dataPost.type != "" && this.dataPost.picture != "") {
                this.disableBtnPost = false;
            }
            else{
                this.disableBtnPost = true;
            }

            // if (radio == "N") {
            //     this.newsIsSelected = true;
            //     this.newWhat = 'news';
            // }
            // else{
            //     this.newsIsSelected = false;
            //     this.newWhat = 'announcement';
            // }

        },

        selectBgImage(urlImage){
            this.dataPost.picture = urlImage;

            if (this.dataPost.title != "" && this.dataPost.type != "" && this.dataPost.picture != "") {
                this.disableBtnPost = false;
            }
            else{
                this.disableBtnPost = true;
            }
        },

        cancelForm(){
            this.$refs.form.reset();
            this.dataPost.title = '';
            this.dataPost.type = '';
            this.dataPost.picture = '';
            this.dialogFormPost = false
            this.disableBtnPost = true;
        },

        postForm(){

            // console.log(this.dataPost);

            if (this.dataPost.title != '' && this.dataPost.type != '' && this.dataPost.picture != '') {
                axios.post(process.env.VUE_APP_API_URL + `announce/add`, this.dataPost, {
                    headers: {
                        Authorization: "Bearer " + this.$store.getters.user.token,
                    },
                })
                .then((response) => { //eslint-disable-line
                    // console.log(response);
                    this.dialogSuccessPostAnnc = true;
                })
                .catch((error) => {
                    console.log(error.response);
                    this.dialogSuccessPostAnnc = false;
                    this.dialogError = true;
                });
            }
            else{
                this.errorBlankFill = true;
            }

            

        }

    },
    mounted(){
        this.load();
        this.getAllData();
    }

}

</script>

<style lang="scss">
@import '~scss/main';
@import "~vue-wysiwyg/dist/vueWysiwyg.css";



</style>
