<template>
    <v-container>

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-bullhorn-variant</v-icon>
            Admin - Announcement
        </v-card-title>

        <v-tabs
          v-model="tabs"
          show-arrows="mobile"
        >

          <v-tab
            v-for="item in itemsDashboard"
            :key="item.tab"
            class="tab-icon"
          >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
        v-model="tabs"
        >
          <v-tab-item class="container-tab-modul">
              <announcement>

              </announcement>
          </v-tab-item>
          <v-tab-item class="container-tab-modul">
              <public-holiday>

              </public-holiday>
          </v-tab-item>
          <!-- <v-tab-item class="container-tab-modul">
              <events>

              </events>
          </v-tab-item> -->
        </v-tabs-items>

    </v-container>
</template>

<script>
import Announcement from '@/components/systemAdmin/Announcement/Announcement';
import PublicHoliday from '@/components/systemAdmin/Announcement/PublicHoliday';
// import Events from '@/components/systemAdmin/Announcement/Events';

export default {
    components: {
        'announcement': Announcement,
        'public-holiday': PublicHoliday,
        // 'events': Events
    },
     data: () => ({

        tabs: null,
        itemsDashboard: [
          {
            name: 'Announcement',
            icon: 'mdi-bullhorn-variant'
          },
          {
            name: 'Public Holiday',
            icon: 'mdi-beach'
          },
          // {
          //   name: 'Events',
          //   icon: 'mdi-calendar-multiselect'
          // }
        ],

        tabNotification: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

    }),
}

</script>

<style lang="scss">
@import '~scss/main';



</style>
